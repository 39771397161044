body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modalStyle .ant-modal-content {
  border-radius: 20px;
  background-color:  #023047;
}

.lotDetailsTitle {
  font-size: 48px;
}

@media (max-width: 500px) {
  .lotDetailsTitle {
      font-size: 30px;
    }
}
